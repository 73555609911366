import { Grid, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useStyles } from './styles'
import Header from '../header'
import { useDispatch, useSelector } from 'react-redux'
import { ApiCrearServicio, ApiEditarServicio, ApiEliminarServicio, ApiObtenerServicios } from '../../../api/servicios'
import { toast } from "../../reusables/toast.jsx"
import { Spinner } from 'react-bootstrap'

const Servicios = () => {
    const dispatch = useDispatch()
    const { classes } = useStyles()
    const [name, setName] = useState("")
    const { servicios, isLoadingServicios } = useSelector((state) => state.servicios)
    const [edit, setEdit] = useState({ open: false, idServicio: "", name: "" })


    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleOpenEdit = ({ idServicio, nombre }) => {
        setEdit({ open: true, idServicio: idServicio, name: nombre })
    }

    const handleCloseEdit = () => {
        setEdit({ open: false, idServicio: "", name: "" })
    }

    const handleChangeNameEdit = (e) => {
        setEdit({ ...edit, name: e.target.value })
    }

    const createGrupo = async () => {
        if (name.length) {
            let result = await ApiCrearServicio({ nombre: name })
            if (result.status === 201 && result.message === 'servicio creado con exito') {
                toast.fire({ icon: 'success', title: `Servicio ${name} creado con exito!` })
                dispatch(ApiObtenerServicios())
            } else {
                toast.fire({ icon: 'error', title: `No pudimos procesar la solcitud` })
            }
        } else {
            toast.fire({ icon: 'error', title: `Debes completar el nombre del servicio!` })
        }
        setName("")
    }

    const upadateGrupo = async ({ idServicio }) => {
        if (edit?.name.length) {
            let result = await ApiEditarServicio({ nombre: edit.name, idServicio: idServicio })
            if (result.status === 200 && result.message === 'servicio actualizado con exito') {
                toast.fire({ icon: 'success', title: `Grupo ${edit.name} actualizada con exito!` })
                handleCloseEdit()
                dispatch(ApiObtenerServicios())
            } else {
                toast.fire({ icon: 'error', title: `No pudimos procesar la solcitud` })
            }
        }else{
            toast.fire({ icon: 'error', title: `No puedes dejar campos incompletos` })
        }
    }

    const deleteGrupo = async ({ idServicio, nombre }) => {
        Swal.fire({
            title: `Seguro que desea eliminar el servicio ${nombre}?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let resultDelete = await ApiEliminarServicio({ idServicio })
                if (resultDelete.status === 200 && resultDelete.message === 'servicio eliminado con exito') {
                    toast.fire({ icon: 'success', title: `Servicio ${edit.name} eliminado con exito!` })
                    dispatch(ApiObtenerServicios())
                } else {
                    toast.fire({ icon: 'error', title: `No pudimos procesar la solcitud` })
                }
            }
        })
    }

    useEffect(() => {
        dispatch(ApiObtenerServicios())
    }, [])

    return (
        <Grid container className={classes.page}>
            <Grid item xs={12} className='sticky-top'>
                <Header />
            </Grid>
            {!isLoadingServicios  ? 
            <Grid container className={classes.containerRows} columnGap={3} rowGap={3} my={1}>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h5" my={2}>
                        Listado de Servicios
                    </Typography>
                </Grid>
                <Grid item xs={10} sx={{xIndex: 0}}>
                    <Grid display="flex" justifyContent="end" columnGap={1}>
                        <TextField
                            style={{ backgroundColor: '#fff' }}
                            onChange={handleChangeName}
                            value={name}
                            label="Nuevo Servicio"
                            variant="outlined"
                            size="small" />
                        <Button
                            style={{ backgroundColor: '#fff' }}
                            onClick={createGrupo}
                            variant="outlined">
                            Crear
                        </Button>
                    </Grid>
                </Grid>
                {!isLoadingServicios && servicios.result.length && servicios.result.map(({ nombre, idServicio }) => (
                    <Grid item xs={10} className={classes.rowServicio} key={idServicio}>
                        <Grid container>
                            <Grid item xs={12} lg={6} display="flex" alignItems="center" width="100%">
                                {edit.open && edit.idServicio === idServicio ?
                                    <Grid display="flex" alignItems="center" width="100%">
                                        <TextField
                                            style={{ backgroundColor: '#fff', marginLeft: '1rem' }}
                                            onChange={handleChangeNameEdit}
                                            value={edit.name}
                                            fullWidth
                                            label="Servicio"
                                            variant="standard"
                                            size="small"
                                        />
                                        <Grid display="flex">
                                            <Button size="small" style={{ backgroundColor: '#fff', marginLeft: '1rem' }} onClick={() => upadateGrupo({ idServicio })} variant="outlined">Guardar</Button>
                                            <Button size="small" style={{ backgroundColor: '#fff', marginLeft: '1rem' }} onClick={handleCloseEdit} variant="outlined" color="error">Cancelar</Button>
                                        </Grid>
                                    </Grid>
                                    : <Typography variant="body1" fontWeight={500} letterSpacing={1}>{nombre.toUpperCase()}</Typography>
                                }
                            </Grid>
                            {
                                edit.open && edit.idServicio === idServicio ? "" :
                                    <Grid display="flex" style={{ marginLeft: 'auto' }}>
                                        <Grid style={{ marginLeft: '1rem' }}>
                                            <Button variant="outlined" size="small" color="success" onClick={() => handleOpenEdit({ idServicio, nombre })}>Editar</Button>
                                        </Grid>
                                        <Grid style={{ marginLeft: '1rem' }}>
                                            <Button variant="outlined" size="small" color="error" onClick={() => deleteGrupo({ idServicio, nombre })}>Eliminar</Button>
                                        </Grid>
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            : <Grid width="100%" mt={2} display="flex" justifyContent="center">
            <CircularProgress />
        </Grid>}
        </Grid>
    )
}

export default Servicios