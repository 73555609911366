import { makeStyles } from 'tss-react/mui';
import { theme } from "../../../theme"

export const useStyles = makeStyles()({
    rowServiceHeader: {
        backgroundColor: theme.palette.primary.medium,
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: '0.75rem',
        margin: '0.25rem',
    },
    rowService: {
        backgroundColor: theme.palette.lightGrey.medium,
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "space-between",
        padding: '0.75rem',
        margin: '0.25rem',
        border: `1px solid ${theme.palette.lightGrey.main}`,
        borderRadius: '0.25rem',
    },
    fontBodyResponsive: {
        fontFamily: "revert-layer",
        fontSize: "15px !important",
        '@media (max-width: 780px)': {
            fontSize: "9px !important"
        }
    },
    boxData: {
        display:"flex",
        alignItems:"center",
        width: "40%",
        '@media (max-width: 780px)': {
            display: "block"
        }
    }
})