import { padding } from '@mui/system';
import { makeStyles } from 'tss-react/mui';
import { theme } from "../../../theme"

export const useStyles = makeStyles()({
    modalCrearCliente: {
        position: 'absolute',
        top: '38%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        minHeight: '40vh',
        backgroundColor: theme.palette.white.main,
        overflow: 'auto',
        padding: '2rem',
        
        '@media (min-width: 780px)': {
            display: "flex",
            alignItems: "center",
            width: '45%',
            maxHeight: '70vh',
        }
    },
    tableClientes: {
        height: 'auto',
        // display: "flex",
        overflow: 'auto'
    },
    toolBarFooter: {
        backgroundColor: '#fff',
        display: "flex",
        flexDirection: "row"
    },
    anchorTable: {
        [theme.breakpoints.down("xs")]: {
            width: '95%'
        },
        [theme.breakpoints.up("lg")]: {
            width: '80%'
        },
        [theme.breakpoints.up("xl")]: {
            width: '70%'
        },
        margin: 'auto'
    },
    appBar: {
        position: "static",
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    toolBar: {
        backgroundColor: theme.palette.white.main,
    },
    tableRow: {
        fontWeight: 'bold !imporatant',
        backgroundColor: "#1976d2"
    }
})