import { createSlice } from "@reduxjs/toolkit"

export const detalleClienteSlice = createSlice({
    name: 'detalleCliente',

    initialState: {
        serviciosAsignados: [],
        isLoadingServiciosAsignados: true
    },

    reducers: {
        setServiciosAsignados: (state, action) => {
            state.isLoadingServiciosAsignados = true
            state.serviciosAsignados = action.payload.serviciosAsignados.result
            state.isLoadingServiciosAsignados = false
        }
    }

})

export const { setServiciosAsignados } = detalleClienteSlice.actions