import { configureStore } from "@reduxjs/toolkit"
import { clientesSlice } from "../components/dashboard/clientes/slices"
import { detalleClienteSlice } from "../components/dashboard/detalleCliente/slices"
import { serviciosSlice } from "../components/dashboard/servicios/slice"
import { vencimientosSlice } from "../components/dashboard/vencimientos/slices"

export const store = configureStore({
    reducer: {
        clientes: clientesSlice.reducer,
        detalleCliente: detalleClienteSlice.reducer,
        servicios: serviciosSlice.reducer,
        vencimientos: vencimientosSlice.reducer,
    },
})