import { Grid, Typography, TextField, Button, Alert } from '@mui/material'
import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { crearCliente, obtenerClientes } from '../../../../api/clientes'
import useForm from '../../../../hooks/useForm'
import CustomModal from '../../../reusables/customModal'
import { toast } from '../../../reusables/toast'
import { useStyles } from '../styles.js'

const ModalCrearCliente = ({ open, handleClose }) => {
    const dispatch = useDispatch()
    const { classes } = useStyles()
    const { form, handleChangeField, resetForm } = useForm()
    const [error, setError] = useState(false)

    const createNewClient = async () => {
        if (form.nombre.length && form.dniocuit.length && form.direccion.length && form.razonSocial.length && form.telefono.length) {
            setError(false)
            const result = await crearCliente({
                nombre: form.nombre,
                dniocuit: form.dniocuit,
                direccion: form.direccion,
                razonSocial: form.razonSocial,
                telefono: form.telefono
            })
            if (result.status === 201) {
                dispatch(obtenerClientes())
                toast.fire({ 'icon': 'success', title: `${result.message}` })
                handleClose()
                resetForm()
            } else {
                toast.fire({ 'icon': 'error', title: `error: ${result.message} - status: ${result.status} ` })
            }
        } else {
            setError(true)
        }
    }

    return (
        <form>
            <CustomModal open={open} handleClose={handleClose} style={classes.modalCrearCliente}>
                <Grid container>
                    <Fragment>
                        <Typography variant="h6" gutterBottom>
                            Nuevo Cliente
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="nombre"
                                    name="nombre"
                                    label="Nombre y Apellido"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                    onChange={handleChangeField}
                                    value={form.nombre}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="dniocuit"
                                    name="dniocuit"
                                    label="Dni o Cuit"
                                    fullWidth
                                    autoComplete="family-name"
                                    variant="standard"
                                    type="number"
                                    onChange={handleChangeField}
                                    value={form.dniocuit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="direccion"
                                    name="direccion"
                                    label="Direccion"
                                    fullWidth
                                    autoComplete="shipping address-line1"
                                    variant="standard"
                                    onChange={handleChangeField}
                                    value={form.direccion}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="razonSocial"
                                    name="razonSocial"
                                    label="Razon Social"
                                    fullWidth
                                    autoComplete="shipping address-level2"
                                    variant="standard"
                                    onChange={handleChangeField}
                                    value={form.razonSocial}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="telefono"
                                    name="telefono"
                                    label="Telefono Formato WhatsApp (54 9 351 607 6315)"
                                    onInput={(e) => {
                                        var InputElement = (e.target);
                                        InputElement.value = Math.max(0, parseInt(InputElement.value)).toString().slice(0, 13);
                                    }}
                                    fullWidth
                                    type="number"
                                    variant="standard"
                                    onChange={handleChangeField}
                                    value={form.telefono}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {error ? <Alert severity="error"> Debes completar todos los campos !</Alert> : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={createNewClient}>
                                    Crear Cliente
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                </Grid>
            </CustomModal>
        </form>
    )
}

export default ModalCrearCliente
