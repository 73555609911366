import { useState } from "react"

const useForm = () => {
    const initialState = {
        //ALTA DE CLIENTES
        nombre: "",
        dniocuit: "",
        direccion: "",
        razonSocial: "",
        telefono: "",
    }
    const [form, setForm] = useState(initialState)

    const handleChangeField = ({ target }) => {
        const { name, value } = target
        setForm({ ...form, [name]: value });
    }

    const resetForm = () => {
        setForm(initialState)
    }

    return { form, handleChangeField, resetForm, setForm }
}

export default useForm;