import { useEffect, useState } from 'react'
import { Grid, Typography, IconButton } from '@mui/material'
import { useStyles } from '../style.js'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { ApiObtenerServicios } from "../../../../api/servicios.js"
import { formatDateYYYY_MM_DD } from '../../../../utilities/formatDate.js';
import { apiAsignarServicio, apiEditarServicioAsignado, apiEliminarServicioAsignado, apiObtenerServiciosAsignadosPorId } from '../../../../api/serviciosAsignados.js';
import { toast } from '../../../reusables/toast.jsx';
import Swal from 'sweetalert2';

import Modal from "./modal.jsx"
import TableServiciosAsignados from './tableServiciosAsignados.jsx';

const initialDate = { first: formatDateYYYY_MM_DD(new Date()), second: "" }
const initialPrice = { formattedValue: "", value: "" }

const ServiciosPrestados = ({ isLoadingServiciosAsignados, serviciosAsignados, idCliente }) => {
    const dispatch = useDispatch()
    const { classes } = useStyles()
    const { servicios, isLoadingServicios } = useSelector((state) => state.servicios)
    const [servicioSeleccionado, setServicioSeleccionado] = useState("")
    const [dataEditable, setDataEditable] = useState([])
    const [price, setPrice] = useState(initialPrice)
    const [date, setDate] = useState(initialDate)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState("")

    const handleOpen = ({ create, data }) => {
        if (!create) {
            setDate({ first: formatDateYYYY_MM_DD(data.fechaDeAlta), second: formatDateYYYY_MM_DD(data.fechaDeVencimiento) })
            setServicioSeleccionado(data.nombre)
            setPrice({ formattedValue: "", value: data.importe })
            setOpen(true)
            setDataEditable([data])
        } else {
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setDate(initialDate)
        setServicioSeleccionado("")
        setPrice(initialPrice)
        setDataEditable([])
    }

    const handleSelectService = (event) => {
        setServicioSeleccionado(event.target.value);
        console.log(event.target.value)
    }

    const handleChangeDate = ({ firstDate, secondDate }) => {
        setDate({
            first: firstDate ? firstDate : date.first,
            second: secondDate ? secondDate : date.second
        })
    }

    const handleChangePrice = (values) => {
        const { formattedValue, value } = values;
        setPrice({ formattedValue: formattedValue, value: value })
    }

    const asignarServicio = async () => {
        const result = await apiAsignarServicio({
            idCliente: idCliente,
            fechaDeAlta: date.first,
            fechaDeVencimiento: date.second,
            importe: price.value,
            servicio: servicioSeleccionado,
        })
        if (result.status === 201 && result.message === 'servicio asignado con exito') {
            dispatch(apiObtenerServiciosAsignadosPorId({ idCliente: idCliente }))
            handleClose()
            toast.fire({ title: result.message, icon: 'success' })
        } else if (result.status === 400) {
            setError('¡ Debes completar todos los campos !')
        } else {
            setError('¡ Ocurrio un error, no pudimos procesar tu solicitud !')
        }
    }

    const editarServicioAsignado = async () => {
        if (dataEditable.length) {
            const { idServicioAsignado } = dataEditable[0]

            const response = await apiEditarServicioAsignado({
                idCliente: idCliente,
                idServicioAsignado: idServicioAsignado,
                fechaDeAlta: date.first,
                fechaDeVencimiento: date.second,
                importe: price.value,
                servicio: servicioSeleccionado
            })

            if (response.status === 200) {
                dispatch(apiObtenerServiciosAsignadosPorId({ idCliente: idCliente }))
                handleClose()
                toast.fire({ title: response.message, icon: 'success' })
            } else if (response.status === 400) {
                setError('¡ Debes completar todos los campos !')
            } else {
                handleClose()
                toast.fire({ title: response.message, icon: 'error' })
            }
        }
    }

    const eliminarServicioAsignado = async (idServicioAsignado, nombre) => {
        Swal.fire({
            title: `¿ Desea eliminar el servicio ${nombre} ?`,
            text: `Esta accion es irreversible !`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await apiEliminarServicioAsignado({ idCliente, idServicioAsignado })
                if (response.status === 200) {
                    dispatch(apiObtenerServiciosAsignadosPorId({ idCliente: idCliente }))
                    toast.fire({ icon: 'success', title: response.message })
                } else if (response.status === 400) {
                    toast.fire({ icon: 'warning', title: response.message })
                } else {
                    toast.fire({ icon: 'error', title: response.message })
                }
            }
        })

    }

    useEffect(() => {
        dispatch(ApiObtenerServicios())
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} className={classes.headerTop}>
                <Typography>Servicios Prestados</Typography>
                <IconButton onClick={() => handleOpen({ create: true })}>
                    <AddCircleIcon style={{ color: "#fff" }} />
                </IconButton>
            </Grid>

            <Modal
                classes={classes}
                error={error}
                date={date}
                price={price}
                open={open}
                servicioSeleccionado={servicioSeleccionado}
                isLoadingServicios={isLoadingServicios}
                servicios={servicios}
                dataEditable={dataEditable}
                handleClose={handleClose}
                handleSelectService={handleSelectService}
                handleChangeDate={handleChangeDate}
                handleChangePrice={handleChangePrice}
                asignarServicio={asignarServicio}
                editarServicioAsignado={editarServicioAsignado}
            />

            <TableServiciosAsignados
                classes={classes}
                isLoadingServiciosAsignados={isLoadingServiciosAsignados}
                serviciosAsignados={serviciosAsignados}
                handleOpen={handleOpen}
                eliminarServicioAsignado={eliminarServicioAsignado}
            />

        </Grid>
    )
}

export default ServiciosPrestados