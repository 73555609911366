import { Grid } from '@mui/material'
import React from 'react'
import Header from './header'
import imgDash from "../../assets/Vivianalozahys.webp"

const Dashboard = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid container>
        <Grid item xs={12} textAlign="center" mt={5}>
          <img src={imgDash} className="floating" height={500} width={500} alt="logo animado viviana" />
        </Grid>
        <h1 className='fontName mx-auto mt-3'>Viviana Loza</h1>
      </Grid>
    </Grid>
  )
}

export default Dashboard
