export const getStorage = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

export const setStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const deleteStorage = (key) => {
    localStorage.removeItem(key)
}

export const clearStorage = () => {
    localStorage.clear()
}

export const getIsAdmin = () => {
    let isAdmin = JSON.parse(localStorage.getItem('user')).tipo_usuario
    return isAdmin === 'ADMIN'
}