import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const CustomModal = ({ children, style, open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={style}>
                {children}
            </Box>
        </Modal>
    )
}

export default CustomModal