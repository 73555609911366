import { makeStyles } from 'tss-react/mui';
import { theme } from "../../theme"

export const useStyles = makeStyles()({
    fontBodyResponsive: {
        fontFamily:"revert-layer",
        fontSize: "15px !important",
        '@media (max-width: 780px)': {
            fontSize: "9px !important"
        }
    },
})