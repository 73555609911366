import { CircularProgress, Grid, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarCliente, obtenerClientes } from '../../../api/clientes';
import SpinnerLinear from '../../reusables/spinnerLinear';
import TableRow from '@mui/material/TableRow';
import { Table, TableBody, TableHead, TableCell } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../reusables/StyledTable';
import CustomModalCliente from './modalCrearCliente';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useStyles } from './styles';
import { toast } from '../../reusables/toast';
import Swal from 'sweetalert2';
import useForm from '../../../hooks/useForm';
import ModalEditarCliente from './modalEditarCliente';
import { setClientes } from './slices';
import { useNavigate } from 'react-router-dom';

const TableClientes = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { form, setForm } = useForm()
    const { classes } = useStyles()
    const { clientes, isLoading } = useSelector((state) => state.clientes)
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [clienteSelected, setClienteSelected] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [search, setSearch] = useState("")

    console.log(clientes)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpenModalAdd = () => setOpenModalAdd(true)
    const handleCloseModalAdd = () => setOpenModalAdd(false)

    const handleOpenModalEdit = (e, cliente) => {
        e.stopPropagation()
        setOpenModalEdit(true)
        setClienteSelected([cliente])
    }

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false)
        setClienteSelected([])
    }

    const deleteCliente = async (e, idCliente, nombre) => {
        e.stopPropagation()
        Swal.fire({
            title: `¿ Seguro desea eliminiar al cliente ${nombre} ?`,
            text: `Esta accion es irreversible !`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await eliminarCliente({ idCliente: idCliente })
                if (result.status === 200) {
                    dispatch(obtenerClientes())
                    toast.fire({ 'icon': 'success', title: `${result.message}` })
                } else {
                    toast.fire({ 'icon': 'error', title: `error: ${result.message} - status: ${result.status} ` })
                }
            }
        })
    }

    const handleChangeSearch = async (e) => {
        const resultSearch = e.target.value?.toString().toLowerCase()
        setSearch(resultSearch)

        if (resultSearch.length) {
            const result = clientes.result.filter((cliente) =>
                cliente?.nombre.toLowerCase().toString().includes(search)
                || cliente?.telefono.toString().includes(search)
                || cliente?.dniocuit.toString().includes(search)
                || cliente?.razonSocial.toLowerCase().toString().includes(search)
            )
            dispatch(setClientes({ clientes: { ...clientes, result: result } }));
        } else {
            dispatch(obtenerClientes())
        }
    }

    useEffect(() => {
        dispatch(obtenerClientes())
    }, [])

    return (
        <Grid container mt={2}>
            {!isLoading ?
                <Grid className={classes.anchorTable} py={2}>
                    <Typography component='h1' variant="h5">Gestion de Clientes</Typography>
                </Grid> : ""}

            {!isLoading && clientes.result !== undefined ?
                <Paper className={classes.anchorTable}>
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.toolBar} elevation={0} >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <SearchIcon sx={{ display: 'block' }} />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        onChange={handleChangeSearch}
                                        value={search}
                                        placeholder="Buscar por nombre, telefono, dniocuit o razon social"
                                        InputProps={{ disableUnderline: true, sx: { fontSize: 'default' } }}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item display="flex">
                                    <Button variant="contained" size="small" sx={{ mr: 1 }} onClick={handleOpenModalAdd}>
                                        Añadir
                                    </Button>
                                    <CustomModalCliente open={openModalAdd} handleClose={handleCloseModalAdd} />
                                    <Tooltip title="Reload">
                                        <IconButton onClick={() => { dispatch(obtenerClientes()) }}>
                                            <RefreshIcon color="inherit" sx={{ display: 'block' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Grid container className={classes.tableClientes}>
                        <Table sx={{ width: '100%', height: "auto" }} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell style={{ color: "#fff" }}>ID Cliente</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Nombre</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Razon Social</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Telefono</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Dni o Cuit</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Editar</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoading ?
                                    clientes !== undefined && clientes.result !== undefined ? clientes.result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <StyledTableRow key={row.idCliente} onClick={(e) => {navigate(`/dashboard/clientes/${row.idCliente}`)}}>
                                            <StyledTableCell>{row.idCliente}</StyledTableCell>
                                            <StyledTableCell>{row.nombre}</StyledTableCell>
                                            <StyledTableCell>{row.razonSocial}</StyledTableCell>
                                            <StyledTableCell>{row.telefono}</StyledTableCell>
                                            <StyledTableCell>{row.dniocuit}</StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton onClick={(e) => handleOpenModalEdit(e, row)}>
                                                    <BorderColorIcon color="primary" sx={{ display: 'block' }} />
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton onClick={(e) => { deleteCliente(e, row.idCliente, row.nombre) }}>
                                                    <DeleteIcon color="error" sx={{ display: 'block' }} />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : <p style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>No se encontraron resultados</p>
                                    : <SpinnerLinear />}
                            </TableBody>
                        </Table>
                    </Grid>

                    {!isLoading && clienteSelected.length ?
                        <ModalEditarCliente
                            open={openModalEdit}
                            handleClose={handleCloseModalEdit}
                            clienteSeleccionado={clienteSelected[0]}
                        /> : ""}


                    <Toolbar className={classes.toolBarFooter}>
                        {!isLoading ?
                            <TablePagination
                                className='ms-auto'
                                rowsPerPageOptions={[]}
                                component="div"
                                page={page}
                                count={clientes.result.length}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                            />
                            : ""}
                    </Toolbar>

                </Paper>
                :
                <Grid width="100%" display="flex" justifyContent="center">
                    <CircularProgress />
                </Grid>}
        </Grid >
    )
}

export default TableClientes
