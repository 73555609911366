import { createSlice } from "@reduxjs/toolkit"

export const vencimientosSlice = createSlice({
    name: 'vencimientos',

    initialState: {
        vencimientos: [],
        page: 1,
        limit: 8,
        isLoading: true
    },

    reducers: {
        setVencimientos: (state, action) => {
            state.isLoading = true
            state.page = action.payload.page
            state.limit = action.payload.limit
            state.vencimientos = action.payload.vencimientos !== undefined && action.payload.vencimientos 
            state.isLoading = false
        },
    }
})

export const { setVencimientos } = vencimientosSlice.actions