import { URL } from "../utilities/url.js"
import { header } from "../utilities/header.js"
import { setServiciosAsignados } from "../components/dashboard/detalleCliente/slices"
import { setVencimientos } from "../components/dashboard/vencimientos/slices"
import { toast } from "../components/reusables/toast.jsx"


export const apiObtenerVencimientosDeServicios = ({ page, action, limit }) => async (dispatch) => {
    try {
        const data = await fetch(URL + `/obtener-servicios-asignados`, header('POST', { page: page, limit: limit }))
        const response = await data.json()
        if (response.status === 200) {
            if (response.result.length) {
                dispatch(setVencimientos({ vencimientos: response.result, page: page, limit: limit }))
            } else {
                toast.fire({ icon: "warning", title: "no hay mas resultados" })
            }
        } else {
            toast.fire({ icon: "warning", title: "no hay mas resultados" })
        }
    } catch (error) {
        console.error(error)
    }
}

export const apiObtenerServiciosAsignadosPorId = ({ idCliente }) => async (dispatch) => {
    try {
        const data = await fetch(URL + `/obtener-servicios-asignados/${idCliente}`, header('POST', {}))
        const response = data.json()
        dispatch(setServiciosAsignados({ serviciosAsignados: await response }))
    } catch (error) {
        console.error(error)
    }
}

export const apiAsignarServicio = async ({ servicio, fechaDeAlta, fechaDeVencimiento, importe, idCliente }) => {
    try {
        const data = await fetch(URL + '/asignar-servicio', header('POST', { servicio, fechaDeAlta, fechaDeVencimiento, importe, idCliente }))
        const response = data.json()
        return response
    } catch (error) {
        console.error(error)
    }
}

export const apiEditarServicioAsignado = async ({ idServicioAsignado, servicio, fechaDeAlta, fechaDeVencimiento, importe, idCliente }) => {
    try {
        const data = await fetch(URL + '/editar-servicio-asignado', header('POST', {
            idCliente: idCliente,
            idServicioAsignado: idServicioAsignado,
            servicio: servicio,
            fechaDeAlta: fechaDeAlta,
            fechaDeVencimiento: fechaDeVencimiento,
            importe: importe,
        }))
        const response = data.json()
        return response
    } catch (error) {
        console.error(error)
    }
}

export const apiEliminarServicioAsignado = async ({ idCliente, idServicioAsignado }) => {
    try {
        const data = await fetch(URL + '/eliminar-servicio-asignado', header('POST', { idCliente, idServicioAsignado }))
        const response = await data.json()
        return response
    } catch (error) {
        console.error(error)
    }
}