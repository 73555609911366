import { Grid, Button, IconButton, Chip } from '@mui/material'
import { useEffect, useState } from 'react'
import Header from '../header'
import TableVencimientos from './tableVencimientos'
import { useDispatch, useSelector } from 'react-redux'
import { apiObtenerVencimientosDeServicios } from '../../../api/serviciosAsignados'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useStyles } from './styles'
import { formatDateDD_MM_YYYY } from '../../../utilities/formatDate'
import TextResponsive from '../../reusables/textResponsive'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom'

const Vencimientos = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { classes } = useStyles()
    const { vencimientos, page, isLoading } = useSelector((state) => state.vencimientos)
    const [limit, setLimit] = useState(8)

    const prevPage = () => {
        dispatch(apiObtenerVencimientosDeServicios({
            limit: limit,
            page: page - 1,
        }))
    }

    const nextPage = () => {
        dispatch(apiObtenerVencimientosDeServicios({
            limit: limit,
            page: page + 1,
        }))
    }

    useEffect(() => {
        dispatch(apiObtenerVencimientosDeServicios({
            limit: limit,
            page: page
        }))
    }, [])

    return (
        <Grid container>

            <Grid item xs={12}>
                <Header />
            </Grid>

            {/* <Grid>
                <FiltrosVencimientos />
            </Grid> */}

            <Grid container display="flex" justifyContent="center" mt={2} >
                <Grid item xs={10} className={classes.rowServiceHeader}>
                    <Grid>
                        <TextResponsive bold color="white" letterSpacing={1}>Servicio Prestado</TextResponsive>
                    </Grid>
                    <Grid ml="auto">
                        <TextResponsive bold color="white" letterSpacing={1}>Fecha de Alta</TextResponsive>
                    </Grid>
                    <Grid ml={2} mr={2}>
                        <TextResponsive bold color="white" letterSpacing={1}>Vencimiento</TextResponsive>
                    </Grid>
                </Grid>
                {!isLoading && vencimientos.length ?
                    vencimientos.map((elem) =>
                        <Grid item xs={10} className={classes.rowService}>
                            <Grid display="flex" alignItems="center" mx={1}>
                                <IconButton onClick={() => navigate(`/dashboard/clientes/${elem.idCliente}`)} ><AccountCircleIcon color="primary" /></IconButton>
                                <TextResponsive>{elem.servicio.toUpperCase()}</TextResponsive>
                            </Grid>

                            <Grid>
                                <Chip label={elem.nombre} size="small" />
                            </Grid>


                            <Grid ml="auto">
                                <Chip label={formatDateDD_MM_YYYY(elem.fechaDeAlta)} size="small" />
                            </Grid>

                            <Grid mx={3.5}>
                                {
                                    new Date(elem.fechaDeVencimiento).toLocaleDateString() <= new Date().toLocaleDateString() ?
                                        <Chip label={formatDateDD_MM_YYYY(elem.fechaDeVencimiento)} color="error" size="small" />
                                        : <Chip label={formatDateDD_MM_YYYY(elem.fechaDeVencimiento)} size="small" color="success" />
                                }
                            </Grid>

                        </Grid>
                    )
                    : <Grid item xs={10}>no hay mas resultados</Grid>}

                {!isLoading ?
                    <Grid item xs={10} display="flex" justifyContent="end">
                        <Grid mx={2}>
                            <Button onClick={prevPage}>Anterior</Button>
                        </Grid>
                        <Grid>
                            <Button onClick={nextPage}>Siguiente</Button>
                        </Grid>
                    </Grid>
                    : ""}
            </Grid>


            <Grid container>
                <TableVencimientos vencimientos={[]} />
            </Grid>

        </Grid>
    )
}

export default Vencimientos
