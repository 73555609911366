import Home from "../components/Home";
import Capacitaciones from "../components/Servicios/ListOfServices/Capacitaciones";
import Habilitaciones from "../components/Servicios/ListOfServices/Habilitaciones";
import InformesHigieneySeguridad from "../components/Servicios/ListOfServices/InformesHigieneySeguridad";
import PlanesDeEvacuacion from "../components/Servicios/ListOfServices/PlanesDeEvacuacion";
import ProgramasDeSecuridad from "../components/Servicios/ListOfServices/ProgramasDeSeguridad"
import ProgramaDeErgonomia from "../components/Servicios/ListOfServices/ProgramaErgonomia"
import PrevencionesART from "../components/Servicios/ListOfServices/PrevencionesART"
import HabilitacionDeBomberos from "../components/Servicios/ListOfServices/HabilitacionDeBomberos";
import MedicionDeRuidos from "../components/Servicios/ListOfServices/MedicionDeRuidos";
import ResiudosGrandesGeneradores from "../components/Servicios/ListOfServices/ResidudosGrandesGeneradores";
import Login from "../components/login";
import Dashboard from "../components/dashboard";
import Clientes from "../components/dashboard/clientes";
import Vencimientos from "../components/dashboard/vencimientos";
import DetalleCliente from "../components/dashboard/detalleCliente";
import Servicios from "../components/dashboard/servicios";

export const routes = {
    'customer': [
        { route: '/', component: <Home /> },
        { route: '/servicios/Informes-Tecnicos', component: <InformesHigieneySeguridad /> },
        { route: '/servicios/Habilitaciones', component: <Habilitaciones /> },
        { route: '/servicios/Programa-de-Seguridad', component: <ProgramasDeSecuridad /> },
        { route: '/servicios/Capacitaciones-y-Formacion', component: <Capacitaciones /> },
        { route: '/servicios/Planes-de-Evacuacion', component: <PlanesDeEvacuacion /> },
        { route: '/servicios/Programa-de-Ergonomia', component: <ProgramaDeErgonomia /> },
        { route: '/servicios/Prevenciones-de-ART', component: <PrevencionesART /> },
        { route: '/servicios/Habilitacion-de-Bomberos', component: <HabilitacionDeBomberos /> },
        { route: '/servicios/Medicion-de-Ruidos', component: <MedicionDeRuidos /> },
        { route: '/servicios/Grandes-Generadores-de-Resiudos', component: <ResiudosGrandesGeneradores /> },
        { route: '/login-administracion', component: <Login /> },
        { route: '*', component: <Home /> }
    ],
    'admin': [
        //administracion
        { route: '/dashboard', component: <Dashboard /> },
        { route: '/dashboard/servicios', component: <Servicios /> },
        { route: '/dashboard/clientes', component: <Clientes /> },
        { route: '/dashboard/clientes/:idCliente', component: <DetalleCliente /> },
        { route: '/dashboard/vencimientos', component: <Vencimientos /> },

        //vistas customer
        { route: '/servicios/Informes-Tecnicos', component: <InformesHigieneySeguridad /> },
        { route: '/servicios/Habilitaciones', component: <Habilitaciones /> },
        { route: '/servicios/Programa-de-Seguridad', component: <ProgramasDeSecuridad /> },
        { route: '/servicios/Capacitaciones-y-Formacion', component: <Capacitaciones /> },
        { route: '/servicios/Planes-de-Evacuacion', component: <PlanesDeEvacuacion /> },
        { route: '/servicios/Programa-de-Ergonomia', component: <ProgramaDeErgonomia /> },
        { route: '/servicios/Prevenciones-de-ART', component: <PrevencionesART /> },
        { route: '/servicios/Habilitacion-de-Bomberos', component: <HabilitacionDeBomberos /> },
        { route: '/servicios/Medicion-de-Ruidos', component: <MedicionDeRuidos /> },
        { route: '/servicios/Grandes-Generadores-de-Resiudos', component: <ResiudosGrandesGeneradores /> },
        { route: '/login-administracion', component: <Login /> },
        { route: '*', component: <Home /> }
    ]
}