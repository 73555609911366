import { Typography } from "@mui/material"
import { useStyles } from "./styles"

const TextResponsive = ({ children, className, bold, color, letterSpacing }) => {
    const { classes } = useStyles() 
    return <Typography 
    color={color || ""}
    letterSpacing={letterSpacing || 0}
    className={classes.fontBodyResponsive} 
    fontWeight={bold ? 700 : ""}>
        {children}
    </Typography>

}

export default TextResponsive