import { URL } from "../utilities/url.js"
import { header } from "../utilities/header.js"
import { setServicios } from "../components/dashboard/servicios/slice"

export const ApiObtenerServicios = () => async (dispatch) => {
    try {
        const data = await fetch(URL + '/solicitar-servicios', header('POST', {}))
        const response = data.json()
        dispatch( setServicios({ servicios: await response }))
    } catch (error) {
        console.log(error)
    }
}

export const ApiCrearServicio = async ({ nombre }) => {
    try {
        const data = await fetch(URL + '/crear-servicio', header('POST', { nombre: nombre }))
        const response = data.json()
        return response
    } catch (error) {
        console.log(error)
    }
}

export const ApiEditarServicio = async ({ nombre, idServicio }) => {
    try {
        const data = await fetch(URL + '/editar-servicio', header('POST', { nombre: nombre, idServicio: idServicio }))
        const response = data.json()
        return response
    } catch (error) {
        console.log(error)
    }
}

export const ApiEliminarServicio = async ({ idServicio }) => {
    try {
        const data = await fetch(URL + '/eliminar-servicio', header('POST', { idServicio: idServicio }))
        const response = data.json()
        return response
    } catch (error) {
        console.log(error)
    }
} 