import React, { useEffect } from 'react'
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { obtenerClientePorId } from '../../../api/clientes'
import { useNavigate, useParams } from 'react-router-dom'
import { useStyles } from './style'
import InformacionCliente from './InformacionCliente'
import ServiciosPrestados from './serviciosPrestados'
import { apiObtenerServiciosAsignadosPorId } from '../../../api/serviciosAsignados'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DetalleCliente = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { classes } = useStyles()
    const { idCliente } = useParams()
    const { clienteSeleccionado, isLoading } = useSelector((state) => state.clientes)
    const { serviciosAsignados, isLoadingServiciosAsignados } = useSelector((state) => state.detalleCliente)
    const previousUrl = window.history.length > 1 ? window.history.previous : null;

    useEffect(() => {
        dispatch(obtenerClientePorId({ idCliente: idCliente }))
        dispatch(apiObtenerServiciosAsignadosPorId({ idCliente: idCliente }))
    }, [])

    return (
        <Grid container className={classes.containerPage}>
            {isLoading ? <CircularProgress /> : <>

                <Grid item xs={12}>
                    <IconButton m={1} onClick={() => navigate('/dashboard/clientes')}>
                        <ArrowBackIcon color="primary"></ArrowBackIcon>
                    </IconButton>
                </Grid>

                <Grid item xs={11} lg={7}>
                    <InformacionCliente clienteSeleccionado={clienteSeleccionado} isLoading={isLoading} />
                </Grid>

                <Grid item xs={11} lg={7}>
                    <ServiciosPrestados idCliente={idCliente} serviciosAsignados={serviciosAsignados} isLoadingServiciosAsignados={isLoadingServiciosAsignados} />
                </Grid>
            </>
            }
        </Grid>
    )
}

export default DetalleCliente               