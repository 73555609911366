import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/js/dist/collapse";
import "../node_modules/bootstrap/js/dist/dropdown";
import "../node_modules/bootstrap/js/dist/carousel";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import { AuthProvider } from "./context/AuthContext"
import useLocalAuth from "./hooks/useAuth";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
  const { localUser } = useLocalAuth()

  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
          <AuthProvider>
            <Routes>
              {localUser ? routes[localUser.type].map(({ route, component }, index) => (
                <Route key={index} path={route} element={component} />
              )) : ""}
            </Routes>
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
