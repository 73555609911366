import React from "react";
import Contacto from "../../Contacto";
import NavBar from "../../NavBar";
const ResiudosGrandesGeneradores = () => {
  return (
    <>
      <NavBar />
      <div className="container-fluid bg-white">
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-lg-10">
            <h1 className="programaSeguridadTitle">
              {" "}
              Inscripción en el registro unico de grandes generadores de
              residuos (IPA)
            </h1>
          </div>
          <div className="col-10 col-lg-10 text-center">
            <p className="description-Seguridad">
              Los grandes generadores son aquellos que generan 0.4 metros
              cúbicos o 200 kilogramos de residuos sólidos urbanos. Los rubros
              geriátricos, gastronómicos, verdulerías, bares, restaurantes,
              estaciones de servicios y supermercados son los que mayor cantidad
              de Residuos Sólidos
            </p>
            <div className="">
              <img
              className="img-fluid"
                // style={{ width: "50%" }}
                src={require("../../../assets/GrandesGeneradores.png")}
                alt=""
              />
            </div>
            <p className="description-Seguridad mt-4">
              Establece la obligación de inscribirse en el Registro de Grandes
              Generadores del Instituto de Protección Ambiental y Animal y deben
              presentar un plan de Gestión de Residuos Sólidos Urbanos que
              contemple la separación de fracciones, debidamente suscripto por
              profesional idóneo, ante la autoridad de control, e implementar y
              cumplir el mismo, con metas cuantitativas y anuales, quedando bajo
              su responsabilidad y a su exclusivo costo y cargo la gestión
              interna de los Residuos Sólidos Urbanos la disposición inicial, el
              transporte, tratamiento y disposición final de los mismos.
              Ordenanza N° 12.648 Dto. 052 y la nueva Ordenanza 13.228
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-space"></div>
      <Contacto />
    </>
  );
};

export default ResiudosGrandesGeneradores;
