import { Grid } from '@mui/material'
import React from 'react'
import Header from '../header'
import TableClientes from "./tableClientes"

const Clientes = () => {
    return (
        <Grid container bgcolor="#f6f8fc" minHeight="100vh" display="flex" alignContent="start">
            <Grid item xs={12}>
                <Header />
            </Grid>

            <Grid item xs={12}>
                <TableClientes />
            </Grid>

        </Grid>
    )
}

export default Clientes
