import { URL } from "../utilities/url.js"
import { header } from "../utilities/header.js"

export const signIn = async ({ uid, nombre, email }) => {
    try {
        const data = await fetch(URL + '/login', header('POST', {
            uid: uid,   
            nombre: nombre,
            email: email
        }))
        const response = data.json()
        return response
    } catch (error) {
        return console.log(error)
    }
}