import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { signIn } from '../api/login';
import { getStorage, setStorage } from "../cache";

const initialStateUser = {
    isLogged: false,
    type: 'customer',
    name: null
}

const userStorage = getStorage('user') ? getStorage('user') : initialStateUser



const useLocalAuth = () => {
    const [localUser, setLocalUser] = useState(userStorage)

    const authenticate = async (userFirebase) => {
        if (userFirebase !== null || userFirebase !== undefined) {
            const { uid, email, displayName } = userFirebase
            const result = await signIn({ uid: uid, email: email, nombre: displayName })
            
            console.log('server', result)

            if (result?.status === 200 && result.message === 'validado con exito') {
                setLocalUser({
                    isLogged: true,
                    user: result?.result?.nombre,
                    type: result?.result?.role === 'admin' ? 'admin' : 'customer',
                    token: result.token
                })
                setStorage('user', {
                    isLogged: true,
                    user: result?.result?.nombre,
                    type: result?.result?.role === 'admin' ? 'admin' : 'customer',
                    token: result.token
                })
            }
            return result
        }
    }

    const disloggin = () => {
        setLocalUser({ isLogged: false, name: null, type: 'customer', })
    }

    return { localUser, authenticate, disloggin }
}

export default useLocalAuth