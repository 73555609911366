import { createTheme } from "@mui/material";

export const theme = createTheme({
    
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 800,
            lg: 1025,
            xl: 1200,
        },
    },

    drawerWidth: 240,

    palette: {
        primary: {
            main: "#1E81D6",
            medium: "#2699FB",
            faint: "#B9DFFF",
        },
        secondary: {
            main: "#1976d2",
            medium: "#FFBA14",
            faint: "#FBE4AF",
        },
        tertiary: {
            main: "#036B26",
            medium: "#00A537",
            faint: "#9EE7B6",
        },
        alternative: {
            main: "#520B56",
            medium: "#822B87",
            faint: "#C371C8",
        },
        accent: {
            main: "#29343E",
            medium: "#004D9A",
            faint: "#0264C6",
        },
        lightGrey: {
            main: "#E7EBF0",
            medium: "#f6f8fc",
            faint: "#F7F9FB",
        },
        warn: {
            main: "#be1717",
            medium: "#D9534F",
            faint: "#FBA19E",
        },
        darkGrey: {
            main: "#484848",
            medium: "#818181",
            faint: "#BCBCBC",
        },
        white: {
            main: '#fff'
        },
        black: {
            main: '#000'
        }
    }
});