import React from 'react'
import { formatDateYYYY_MM_DD } from '../../../../utilities/formatDate'
import { Grid, Typography, IconButton, FormControl, InputLabel, MenuItem, Select, TextField, Button, Alert } from '@mui/material'
import CustomModal from '../../../reusables/customModal.jsx'
import CurrencyFormat from 'react-currency-format';
import EventIcon from '@mui/icons-material/Event';

const modalAsignarServicio = ({
  classes,
  error,
  date,
  price,
  open,
  isLoadingServicios,
  servicioSeleccionado,
  handleSelectService,
  handleClose,
  servicios,
  handleChangeDate,
  handleChangePrice,
  dataEditable,
  asignarServicio,
  editarServicioAsignado,
}) => {
  console.log(classes)
  return (
    <CustomModal style={classes.modalServicio} open={open} handleClose={handleClose}>
      <Grid container>

        <Grid item xs={12} display="flex" justifyContent="center">
          <EventIcon color="primary" />
          <Typography color="primary">ASIGNACION DE SERVICIOS</Typography>
          <EventIcon color="primary" />
        </Grid>

        <Grid item xs={12} mt={1} display="flex" flexDirection="column" justifyContent="center">

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-1">Seleccionar Servicio</InputLabel>
            <Select
              labelId="demo-simple-select-label-1"
              id="demo-simple-select"
              value={servicioSeleccionado}
              onChange={handleSelectService}
              size="small">
              {
                !isLoadingServicios && servicios.result.length ? servicios.result.map(({ nombre }) =>
                  <MenuItem value={nombre} key={nombre}>{nombre}</MenuItem>
                ) : <MenuItem>No hay servicios disponibles</MenuItem>
              }

            </Select>
            <Typography mt={2} mb={1} mx={0.5} variant="body2">
              FECHA DE ALTA
            </Typography>
            <TextField
              size="small"
              type="date"
              value={date.first}
              onChange={(e) => handleChangeDate({ firstDate: e.target.value })}
            />
            <Typography mt={2} mb={1} mx={0.5} variant="body2">
              FECHA DE VENCIMIENTO
            </Typography>
            <TextField
              size="small"
              type="date"
              value={date.second}
              onChange={(e) => handleChangeDate({ secondDate: e.target.value })}
              inputProps={{ min: formatDateYYYY_MM_DD(date.first) }}
            />
            <Typography mt={2} mb={1} mx={0.5} variant="body2">
              IMPORTE FACTURADO
            </Typography>
            <CurrencyFormat
              value={price.value}
              onValueChange={handleChangePrice}
              className={classes.inputPrice}
              thousandSeparator={true}
              prefix={'$'} />

            <Grid container mt={2}>
              <Grid item xs={12} mb={2}>
                {error.length ? <Alert severity="error">{error}</Alert> : ""}
              </Grid>
              <Grid ml="auto">
                <Button variant="outlined" size="small" onClick={!dataEditable.length ? asignarServicio : editarServicioAsignado}>confirmar</Button>
              </Grid>
              <Grid ml={1}>
                <Button variant="outlined" color="error" size="small" onClick={handleClose}>Cancelar</Button>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </CustomModal>
  )
}

export default modalAsignarServicio