import { URL } from "../utilities/url.js"
import { header } from "../utilities/header.js"
import { setClienteSeleccionado, setClientes } from "../components/dashboard/clientes/slices/index.js"

export const obtenerClientes = () => async (dispatch) => {
    try {
        const data = await fetch(URL + '/solicitar-clientes', header('POST', {}))
        const response = data.json()
        dispatch(setClientes({ clientes: await response }))
    } catch (error) {
        console.log(error)
    }
}

export const obtenerClientePorId =  ({ idCliente }) => async (dispatch) => {
    try {
        const data = await fetch(URL + '/solicitar-cliente', header('POST', { idCliente: idCliente }))
        const response = data.json()
        dispatch(setClienteSeleccionado({ clienteSeleccionado: await response }))
    } catch (error) {
        return console.log(error)
    }
}

export const crearCliente = async ({ nombre, dniocuit, direccion, razonSocial, telefono }) => {
    try {
        const data = await fetch(URL + '/crear-cliente', header('POST', {
            nombre: nombre,
            dniocuit: dniocuit,
            direccion: direccion,
            razonSocial: razonSocial,
            telefono: telefono
        }))
        const response = data.json()
        return response
    } catch (error) {
        return console.log(error)
    }
}

export const eliminarCliente = async ({ idCliente }) => {
    try {
        const data = await fetch(URL + '/eliminar-cliente', header('POST', { idCliente: idCliente }))
        const response = data.json()
        return response
    } catch (error) {
        return console.log(error)
    }
}

export const editarCliente = async ({ nombre, dniocuit, direccion, razonSocial, telefono, idCliente }) => {
    try {
        const data = await fetch(URL + '/editar-cliente', header('POST', {
            nombre: nombre,
            dniocuit: dniocuit,
            direccion: direccion,
            razonSocial: razonSocial,
            telefono: telefono,
            idCliente: idCliente
        }))
        const response = data.json()
        return response
    } catch (error) {
        return console.log(error)
    }
}