import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from '../../context/AuthContext'
import GoogleLogo from "../../assets/google.webp"
import useLocalAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Login = () => {
  const { user, loginWithGoogle, logout, loading } = useAuth()
  const { authenticate, localUser } = useLocalAuth()

  const handleGoogleSignIn = async () => {
    const { user } = await loginWithGoogle()
    if (user?.uid.length && user !== null && user !== undefined) {
      const result = await authenticate(user)
      if (result.status === 200 && result.message === 'validado con exito' && result.result.role === 'admin') {
        window.location.href = '/dashboard'
      } 
      else {
        Swal.fire({
          icon: 'error',
          title: 'No tiene permisos',
          text: `¡Hola ${user.displayName} ! Lo sentimos pero no tienes permisos para acceder`,
          footer: '<p style="text-align:center;">Se informo a soporte que solicitaste acceder, ponte en contacto con el administrador para solicitar permisos</p>'
        })
        logout()
      }
    }
  }

  useEffect(() => {
    if (localUser.isLogged && localUser.type === 'admin') {
      window.location.href = '/dashboard'
    }
  })
  return (
    <Grid container bgcolor="#f6f8fc" minHeight="100vh">
      {!loading  ?
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{ backgroundColor: "white", border: "1px solid #dee2e6cf", padding: '2rem', borderRadius: '3px', marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}        >
            <Grid className="border rounded-circle p-3">
              <img
                src={'https://github.com/joseguti99/DB-IMG-PUBLIC/blob/main/higiene-seguridad-app/HEADER-ICON.png?raw=true'}
                alt="logo empresa viviana loza hys"
                height={75}
                width={75}
                className="rounded"
              />
            </Grid>
            <button className="button-login" onClick={handleGoogleSignIn}>
              <img
                className='bg-white p-2 rounded'
                height={35}
                width={35}
                src={GoogleLogo}
                alt="logo ingresar con google" />
              <Typography mx={1} component="h6" variant="body1">SIGN IN </Typography>
              <div></div>
            </button>
          </Box>
        </Container>
        : ""}
    </Grid>
  )
}

export default Login
