import { makeStyles } from 'tss-react/mui';
import { theme } from "../../../theme"

export const useStyles = makeStyles()({
    page: {
        backgroundColor: theme.palette.lightGrey.medium,
        height:'auto',
        minHeight: '100vh',
        display: 'flex',
        flexDirection:'column'
    },
    rowServicio:{
        display:"flex", 
        alignItems:"center",
        alignContent:"center",
        padding: '1rem',
        backgroundColor: theme.palette.white.main,
        boxShadow: `0.50px 0.75px 0.50px 0px ${theme.palette.lightGrey.main}`,
        cursor:'pointer',
        '&:hover': {
            borderLeft: `4px solid ${theme.palette.primary.medium}`,
            color: theme.palette.primary.medium
        }
    },
    containerRows:{
        display:"flex",
        justifyContent:"center"
    }
})