import { padding } from '@mui/system';
import { makeStyles } from 'tss-react/mui';
import { theme } from "../../../theme"

export const useStyles = makeStyles()({
    containerPage: {
        backgroundColor: theme.palette.lightGrey.medium,
        height:'auto',
        minHeight: '100vh',
        display: 'flex',
        flexDirection:'row',
        justifyContent:"center",
        alignContent:"start"
    },
    headerTop:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        border: `1px solid ${theme.palette.primary.medium}`,
        backgroundColor: theme.palette.primary.medium,
        padding: '0.50rem',
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem',
        marginTop: '1rem',
        color:'white'
    },
    content: {
        backgroundColor: theme.palette.white.main,
        borderLeft: `1px solid ${theme.palette.lightGrey.main}`,
        borderRight: `1px solid ${theme.palette.lightGrey.main}`,
        borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
        borderBottomLeftRadius: '0.20rem',
        borderBottomRightRadius: '0.20rem',
        padding: '0.25rem'
    },
    rowTable: {
        display:"flex", 
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        padding: '0.50rem 0.25rem 0.50rem 0.25rem',
        borderBottom: `1px solid ${theme.palette.lightGrey.main}`,

        '&:hover':{
            backgroundColor: theme.palette.lightGrey.faint,
            cursor: 'pointer'
        }
    },
    rowTableServicios:{
        display:"flex", 
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        padding: '0.25rem',
        marginTop: '0.50rem',
        
        '&:hover':{
            backgroundColor: theme.palette.lightGrey.faint,
            cursor: 'pointer'
        }
    },
    rowTableServiciosHeader:{
        display:"flex", 
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        padding: '0.40rem',
        borderRadius: '0.25rem',
        backgroundColor: theme.palette.lightGrey.main,
    },
    modalServicio: {
        position: 'absolute',
        top: '38%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxHeight: '90vh',
        minHeight: '40vh',
        backgroundColor: theme.palette.white.main,
        overflow: 'auto',
        padding: '2rem',
        
        '@media (min-width: 780px)': {
            display: "flex",
            alignItems: "start",
            width: '45%',
            maxHeight: '70vh',
        }
    },
    inputPrice:{
        font: 'inherit',
        letterSpacing: 'inherit',
        border: 0,
        boxSizing: 'content-box',
        background: 'none',
        height: '1.4375em',
        display: 'block',
        minWidth: 0,
        width: '100%',
        border: `1px solid #c8c8c8`,
        borderRadius: '0.25rem',
        paddingLeft: '1rem',
        padding: '6px'
    }
})