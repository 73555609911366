import { createSlice } from "@reduxjs/toolkit"

export const serviciosSlice = createSlice({
    name: 'servicios',

    initialState: {
        servicios: [],
        isLoadingServicios: true
    },

    reducers: {
        setServicios: (state, action) => {
            state.isLoadingServicios = true
            state.servicios = action.payload.servicios
            state.isLoadingServicios = false
        },
    }

})

export const { setServicios } = serviciosSlice.actions