
import { Grid, Typography, IconButton, FormControl, InputLabel, MenuItem, Select, TextField, Button, Chip, Alert } from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ArticleIcon from '@mui/icons-material/Article';
import TextResponsive from '../../../reusables/textResponsive';
import { formatDateDD_MM_YYYY } from '../../../../utilities/formatDate';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatPrice } from '../../../../utilities/formatPrice';

const TableServiciosAsignados = ({
    classes,
    isLoadingServiciosAsignados,
    serviciosAsignados,
    handleOpen,
    eliminarServicioAsignado,
}) => {
    return (
        <Grid item xs={12} className={classes.content}>
            <Grid className={classes.rowTableServiciosHeader}>
                <Grid width="32%">
                    <TextResponsive bold>Servicio Prestado</TextResponsive>
                </Grid>
                <TextResponsive bold>Alta</TextResponsive>
                <TextResponsive bold>Vencimiento</TextResponsive>
                <TextResponsive bold>Estado</TextResponsive>
                <TextResponsive bold>Importe</TextResponsive>
                <TextResponsive bold>Editar | Eliminar</TextResponsive>
            </Grid>

            {!isLoadingServiciosAsignados && serviciosAsignados.length ?
                serviciosAsignados.map((servicio) =>

                    <Grid className={classes.rowTableServicios} key={servicio.idServicioAsignado}>

                        <Grid width="30%">
                            <TextResponsive variant="body2"> <ArticleIcon /> {servicio.servicio}</TextResponsive>
                        </Grid>

                        <TextResponsive variant="body2">{formatDateDD_MM_YYYY(servicio.fechaDeAlta)}</TextResponsive>

                        <TextResponsive variant="body2">{formatDateDD_MM_YYYY(servicio.fechaDeVencimiento)}</TextResponsive>

                        {console.log(new Date(servicio.fechaDeVencimiento).toLocaleDateString() + "||" + new Date().toLocaleDateString()) }

                        {
                            new Date(servicio.fechaDeVencimiento).toLocaleDateString() <= new Date().toLocaleDateString() ?
                                <Chip label="Vencido" color="error" size="small" />
                                : <Chip label="Activo" size="small" />
                        }

                        <TextResponsive variant="body2">$ {formatPrice(servicio.importe)}</TextResponsive>

                        <Grid sx={{ mx: { xs: 0, md: 3 } }} display="flex">
                            <IconButton size="small" onClick={() => handleOpen({ create: false, data: servicio })}>
                                <BorderColorIcon color="primary" />
                            </IconButton>
                            <IconButton size="small" onClick={() => eliminarServicioAsignado(servicio.idServicioAsignado, servicio.nombre)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Grid>

                    </Grid>
                )
                : <Grid className={classes.rowTableServicios}>
                    <Typography className='mx-auto'>Este cliente no presenta servicios</Typography>
                </Grid>}
        </Grid>
    )
}

export default TableServiciosAsignados