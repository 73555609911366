import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import useLocalAuth from '../../../hooks/useAuth';
import { Button, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';

function Header(props) {
    const [currentLocation, setCurrentLocation] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { localUser } = useLocalAuth()
    const { logout, loading } = useAuth()

    const pages = ['servicios', 'clientes', 'vencimientos']

    const handleChangePage = (pageSelected) => {
        setCurrentLocation(pageSelected)
        navigate(`/dashboard/${pages[pageSelected]}`)
    }

    const cerrarSesion = async () => {
        await logout()
        if (!loading) {
            window.location.href = '/login-administracion'
        }
    }

    return (
        <Fragment>
            <AppBar component="div"  elevation={1} style={{  position: 'static' }}>
                <Toolbar >
                    <Grid container alignItems="center">
                        <Grid item xs style={{ cursor: 'pointer' }}>
                            <Typography color="inherit" variant="h5" component="h1" onClick={() => navigate('/dashboard')}>
                                Panel Administracion
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item display="flex">
                        <div>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <Avatar alt="Inicial nombre de usuario">
                                    {localUser ? localUser.user.slice(0, 1) : ""}
                                </Avatar>
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={cerrarSesion}>Cerrar Sesion</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar component="div" position="static" elevation={0} >
                <Tabs value={currentLocation} textColor="inherit">
                    <Tab label="servicios" onClick={() => handleChangePage(0)} />
                    <Tab label="clientes" onClick={() => handleChangePage(1)} />
                    <Tab label="vencimientos" onClick={() => handleChangePage(2)} />
                </Tabs>
            </AppBar>
        </Fragment >
    );
}

export default Header;