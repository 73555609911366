import { createSlice } from "@reduxjs/toolkit"

export const clientesSlice = createSlice({
    name: 'clientes',

    initialState: {
        clientes: [],
        clienteSeleccionado: [],
        isLoading: true
    },

    reducers: {
        setClientes: (state, action) => {
            state.isLoading = true
            state.clientes = action.payload.clientes
            state.isLoading = false
        },
        setClienteSeleccionado: (state, action) => {
            state.isLoading = true
            state.clienteSeleccionado = action.payload.clienteSeleccionado.result[0]
            state.isLoading = false
        }
    }

})

export const { setClienteSeleccionado, setClientes, setIsLoading } = clientesSlice.actions