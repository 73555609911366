import React from 'react'
import { Grid, Typography, IconButton } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useStyles } from '../style.js'

const InformacionCliente = ({ clienteSeleccionado, isLoading }) => {
    const { classes } = useStyles()
    
    const message = "Hola+como+estas+?+Nos+contactamos+desde+viviana+loza+higiene+y+seguridad+para+darte+el+mensaje+de+..."
    
    const navigateWsp = () => {
        let url = `https://api.whatsapp.com/send/?phone=+${clienteSeleccionado.telefono}&text=${message}!&type=phone_number&app_absent=0`
        window.open(url, '_blank');
    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.headerTop}>
                <Typography>Informacion del Cliente</Typography>
            </Grid>
            <Grid item xs={12} className={classes.content}>
                {!isLoading ?
                    <>
                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Nombre</Typography>
                            <Typography>{clienteSeleccionado.nombre}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Direccion</Typography>
                            <Typography>{clienteSeleccionado.direccion}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Telefono</Typography>
                            <Typography>{clienteSeleccionado.telefono}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Razon Social</Typography>
                            <Typography>{clienteSeleccionado.razonSocial}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Dni o Cuit</Typography>
                            <Typography>{clienteSeleccionado.dniocuit}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>Alta de Cliente</Typography>
                            <Typography>{new Date(clienteSeleccionado.fechaCreacionCliente).toLocaleDateString()}</Typography>
                        </Grid>

                        <Grid className={classes.rowTable}>
                            <Typography variant="body1" fontWeight={600}>WhatsApp</Typography>
                            <IconButton size="small" onClick={navigateWsp}><WhatsAppIcon color="primary" /></IconButton>
                        </Grid>
                    </>
                    : ""}
            </Grid>
        </Grid>
    )
}

export default InformacionCliente